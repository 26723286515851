import CustomProTable from "@components/CustomProTable"
import { Icon } from "@components/Icons"
import { LayoutAdmin } from "@components/LayoutAdmin"
import { Button, Col } from "antd"
import cx from "classnames"
import React, { useEffect, useRef } from "react"
import { useMedia } from "react-use-media"

import styles from "./style.module.less"

const data = [
  {
    key: "1",
    order: 2938249,
    plan: "Trimestral",
    date: "20/01/2015",
    value: "R$ 34,90",
    middle: "Cartão /C",
    coupon: "Clara10",
    statusRequested: "Pago",
    index: 0,
  },
  {
    key: "2",
    order: 2938249,
    plan: "Trimestral",
    date: "20/01/2015",
    value: "R$ 34,90",
    middle: "Cartão /C",
    coupon: "Clara10",
    statusRequested: "Aberto",
    index: 1,
  },
  {
    key: "3",
    order: 2938249,
    plan: "Trimestral",
    date: "20/01/2015",
    value: "R$ 34,90",
    middle: "Cartão /C",
    coupon: "Clara10",
    statusRequested: "Não Pago",
    index: 2,
  },
  {
    key: "4",
    order: 2938249,
    plan: "Trimestral",
    date: "20/01/2015",
    value: "R$ 34,90",
    middle: "Cartão /C",
    coupon: "Clara10",
    statusRequested: "Pago",
    index: 3,
  },
  {
    key: "5",
    order: 2938249,
    plan: "Trimestral",
    date: "20/01/2015",
    value: "R$ 34,90",
    middle: "Cartão /C",
    coupon: "Clara10",
    statusRequested: "Não Pago",
    index: 4,
  },
  {
    key: "6",
    order: 2938249,
    plan: "Trimestral",
    date: "20/01/2015",
    value: "R$ 34,90",
    middle: "Cartão /C",
    coupon: "Clara10",
    statusRequested: "Aberto",
    index: 5,
  },
]

const EditSubscribers = () => {
  const actionRef = useRef()
  const isMobile = useMedia({ minWidth: "150px", maxWidth: "767px" })

  useEffect(() => {
    if (actionRef.current !== undefined) actionRef.current.reload()
  }, [])

  const columns = [
    {
      title: "Pedido",
      dataIndex: "order",
      className: "drag-visible",
      key: "order",
      hideInSearch: true,
    },
    {
      title: "Plano",
      dataIndex: "plan",
      className: "drag-visible",
      key: "plan",
      hideInSearch: true,
    },
    {
      title: "Data",
      dataIndex: "date",
      className: "drag-visible",
      key: "date",
      hideInSearch: true,
    },
    {
      title: "Valor",
      dataIndex: "value",
      className: "drag-visible",
      key: "value",
      hideInSearch: true,
    },
    {
      title: "Meio",
      dataIndex: "middle",
      className: "drag-visible",
      key: "middle",
      hideInSearch: true,
    },
    {
      title: "Cupom",
      dataIndex: "coupon",
      className: "drag-visible",
      key: "coupon",
      hideInSearch: true,
    },
    {
      title: "Status Pedido",
      dataIndex: "statusRequested",
      className: "drag-visible",
      key: "statusRequested",
      hideInSearch: true,
      render: (text) => (
        <div className="flex flex-row">
          <div
            className={
              text === "Pago"
                ? cx("br-100 mr8", styles.paid)
                : text === "Aberto"
                ? cx("br-100 mr8", styles.open)
                : cx("br-100 mr8", styles.notPay)
            }
          />
          <label className="normal pointer">{text}</label>
        </div>
      ),
    },
    {
      title: "Transação",
      dataIndex: "transaction",
      className: "drag-visible",
      key: "transaction",
      hideInSearch: true,
      render: (text, record) =>
        record.statusRequested === "Pago" ? (
          <Icon name="Order" />
        ) : (
          <Icon name="EyeEdit" />
        ),
    },
  ]

  return (
    <LayoutAdmin title="Assinantes" subTitle="Histórico de pedidos do cliente.">
      {!isMobile && (
        <div className={styles.positionButton}>
          <Button type="primary" className="button-edit">
            Editar perfil
          </Button>
          <Button type="primary" className="button-active-profile">
            Ativar Plano
          </Button>
        </div>
      )}
      <Col span={24}>
        <div className="edit-table">
          <CustomProTable
            actionRef={actionRef}
            headerTitle="Listagem de pedidos"
            columns={columns}
            dataSource={data}
            search={false}
            options={false}
            scroll={{ x: "max-content" }}
            rowKey={(record) => record.id}
            toolBarRender={() => [
              isMobile && (
                <div className="flex flex-row">
                  <Button type="primary" className="button-edit">
                    Editar perfil
                  </Button>
                  <Button type="primary" className="button-active-profile">
                    Ativar Plano
                  </Button>
                </div>
              ),
            ]}
            pagination={{
              defaultCurrent: 1,
              defaultPageSize: 10,
              showTotal: (total) => {
                if (total > 0) {
                  return `Total ${total} ${total > 1 ? "itens" : "item"}`
                }

                return null
              },
            }}
          />
        </div>
      </Col>
    </LayoutAdmin>
  )
}

export default EditSubscribers
